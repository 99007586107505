

import Djeca from "../../components/img/building.png";


function Oskoli() {

    return (
        <section className="Osk">
          
            <br></br>
            <br></br>
            <br></br>
            <img width="400px" src={Djeca} alt="Oskoli"></img>
            <br></br>
            <br></br>
            <h1>Servis u izradi</h1>


       

        </section>
     );

}

export default Oskoli;